var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "abc-anal"
  }, [_c('abc-filters', {
    on: {
      "update-filters": _vm.updateFilters
    }
  }), _c('segments-stats', {
    attrs: {
      "status": _vm.status
    }
  }), _c('abc-tabs', {
    attrs: {
      "items": _vm.items,
      "total": _vm.total,
      "loading": _vm.loading,
      "loading_category": _vm.loading_category
    },
    on: {
      "groupBy": _vm.groupBy,
      "sort": _vm.sort,
      "categoryChange": _vm.categoryChange,
      "update-segment": _vm.updateSegment,
      "handler-pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }