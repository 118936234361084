<template>
  <div class="abc-filters-container">
    <categories-load-modal
      :included_categories="getCategories"
      @add-categories="addCategories"
    />
    <work-period
      type_doc="abc"
      class="date"
    />
    <!--    <date-picker-->
    <!--      v-model="filters.data_range"-->
    <!--      :value="filters.data_range"-->
    <!--      type="daterange"-->
    <!--      format="dd.MM.yyyy"-->
    <!--      :clearable="false"-->
    <!--      class="elm-calendar date"-->
    <!--      placeholder="Период"-->
    <!--    />-->
    <e-select
      :value="getBranches"
      multiple
      select_name="branch"
      class="branch-filter"
      :max_len_chose="10"
      :max_selected_count="1"
      :options="getBranchList"
      placeholder="Магазин"
      @input="inputBranches"
    />

    <div
      class="category-filter"
      @click.capture="showLoadModal"
    >
      <div class="category">
        <span style="white-space: nowrap"> {{ categoriesCount }}</span>
        <img
          src="/img/icons/arrow_up.svg"
          alt="arrow-up"
          style="transform: rotate(180deg)"
        />
      </div>
    </div>
    <e-select
      :value="getSuppliers"
      multiple
      select_name="supplier"
      class="supplier-filter"
      :max_len_chose="10"
      :max_selected_count="1"
      :options="suppliers_list"
      placeholder="Все поставщики"
      @input="inputSuppliers"
    />
    <div
      class="ml-3"
      style="cursor: pointer"
      @click="reset"
    >
      Сбросить
    </div>
  </div>
</template>

<script>
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal'
  import { mapActions, mapGetters } from 'vuex'
  import WorkPeriod from '@/components/WorkPeriod'
  export default {
    name: 'AbcFilters',
    components: { WorkPeriod, CategoriesLoadModal },
    apollo: {
      Suppliers: {
        query: require('../../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.suppliers_list = data.Suppliers ?? []
        }
      }
    },
    data() {
      return {
        suppliers_list: [],
        filters: {
          suppliers: [],
          branches: [],
          selected_categories: []
        }
      }
    },
    computed: {
      ...mapGetters({
        getBranchList: 'settings/getBranchList',
        getSuppliers: 'analytics/getAbcSuppliers',
        getBranches: 'analytics/getAbcBranches',
        getCategories: 'analytics/getAbcOpenedCategory'
      }),
      categoriesCount() {
        if (this.getCategories?.length) {
          return 'Выбрано ' + this.getCategories?.length
        }
        return 'Все группы товаров'
      }
    },
    watch: {
      'filters.selected_categories'() {
        this.update()
      }
    },
    methods: {
      ...mapActions({
        setSuppliers: 'analytics/setAbcSuppliers',
        setBranches: 'analytics/setAbcBranches',
        setCategories: 'analytics/setAbcOpenedCategory'
      }),
      inputBranches(val) {
        this.setBranches(val)
      },
      inputSuppliers(val) {
        this.setSuppliers(val)
      },
      update() {
        this.$emit('update-filters', this.filters)
      },
      async addCategories(cats) {
        this.setCategories(cats)
      },
      showLoadModal() {
        this.$bvModal.show('categories-modal')
      },
      reset() {
        this.setBranches([])
        this.setSuppliers([])
        this.setCategories([])
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .ivu-input {
    height: 33.99px;
    border-color: #e1e1e1;
  }
  .abc-filters-container {
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    .date {
      margin-right: 16px;
    }
    .branch-filter {
      flex: 1 1;
      max-width: 300px;
      min-width: 180px;
      margin-right: 16px;
    }
    .supplier-filter {
      max-width: 300px;

      min-width: 180px;
      flex: 1 1;
      width: 200px !important;
    }
  }
  .category-filter {
    flex: 1;
    align-items: center;
    border: 1px solid #bcbcbc;
    border-radius: 2px;
    display: flex;
    min-width: 180px;
    max-width: 300px;
    padding: 9px 10px;
    margin: 0 16px 0px 0;
    background-color: #fff;
    .category {
      flex: 1;
      display: flex;
      justify-content: space-between;
      span {
        font-family: 'Gilroy-Regular', sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 14px !important;
        display: flex;
        align-items: center;
        color: #bcbcbc !important;
      }
    }
  }
</style>
