var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "segment"
  }, [_c('segment', {
    attrs: {
      "segment": _vm.status.segment_a,
      "name": "A"
    }
  }), _c('segment', {
    attrs: {
      "segment": _vm.status.segment_b,
      "name": "B"
    }
  }), _c('segment', {
    attrs: {
      "segment": _vm.status.segment_c,
      "name": "C"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }