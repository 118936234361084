var render = function render(){
  var _vm$items$find;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table",
    class: {
      'new-height': _vm.items.length
    }
  }, [_c('product-movement-modal', {
    attrs: {
      "product": (_vm$items$find = _vm.items.find(function (obj) {
        var _obj$product;

        return ((_obj$product = obj.product) === null || _obj$product === void 0 ? void 0 : _obj$product.id) === _vm.selected[0];
      })) === null || _vm$items$find === void 0 ? void 0 : _vm$items$find.product
    }
  }), _c('div', {
    staticClass: "table-content"
  }, [_c('div', {
    staticClass: "p-3 d-flex"
  }, [_c('analytics-product-search', {
    attrs: {
      "products": _vm.getProducts
    },
    on: {
      "input": _vm.setProducts
    }
  }), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('group-by', {
    staticClass: "ml-3 mr-3"
  }), _c('e-button', {
    staticClass: "ml-auto mr-3",
    staticStyle: {
      "height": "32px"
    },
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.downloadExel
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/download.svg",
      "alt": "download"
    }
  })]), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.sort-table-modal",
      modifiers: {
        "sort-table-modal": true
      }
    }]
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1)], 1), _c('resizable-table', {
    attrs: {
      "table_name": "abc-tbl_segments",
      "default_fields": _vm.fields,
      "height": _vm.total > 20 ? '550px' : '600px',
      "items": _vm.items,
      "busy": _vm.loading
    },
    on: {
      "sort_change": _vm.sort
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('div', {
          staticClass: "head_id"
        }, [_c('e-checkbox', {
          attrs: {
            "checked": _vm.allSelected
          },
          on: {
            "click": function ($event) {
              _vm.allSelected = !_vm.allSelected;
            }
          }
        }), _c('p', [_vm._v("№")])], 1)];
      },
      proxy: true
    }, {
      key: "body_name",
      fn: function (_ref) {
        var _item$product;

        var item = _ref.item;
        return [_c('div', [_c('analytics-item-row', {
          attrs: {
            "loading_category": _vm.loading_category,
            "row": item,
            "type": "abc",
            "items": _vm.group,
            "selected": _vm.active(item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.id)
          },
          on: {
            "open_category": _vm.open_category,
            "select_product": function ($event) {
              var _item$product2;

              return _vm.select(item === null || item === void 0 ? void 0 : (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : _item$product2.id);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_category",
      fn: function (_ref2) {
        var _item$product3, _item$product4, _item$product4$catego;

        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex float-left align-items-center"
        }, [_vm._v(" " + _vm._s((_item$product3 = item.product) !== null && _item$product3 !== void 0 && _item$product3.id ? ((_item$product4 = item.product) === null || _item$product4 === void 0 ? void 0 : (_item$product4$catego = _item$product4.category) === null || _item$product4$catego === void 0 ? void 0 : _item$product4$catego.name) || 'Товары без категории' : '-') + " ")])];
      }
    }, {
      key: "body_quantity",
      fn: function (_ref3) {
        var _item$product5;

        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [(_item$product5 = item.product) !== null && _item$product5 !== void 0 && _item$product5.id ? _c('div', {
          class: ['segment__' + _vm.getWord(item.quantity_rating), 'mr6', 'segment-word']
        }, [_vm._v(" " + _vm._s(_vm.getWord(item.quantity_rating)) + " ")]) : _vm._e(), _c('div', {
          staticClass: "segment-value"
        }, [_vm._v(_vm._s(_vm.localeData(item.quantity)))])])];
      }
    }, {
      key: "body_revenue",
      fn: function (_ref4) {
        var _item$product6;

        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [(_item$product6 = item.product) !== null && _item$product6 !== void 0 && _item$product6.id ? _c('div', {
          class: ['segment__' + _vm.getWord(item.revenue_rating), 'mr6', 'segment-word']
        }, [_vm._v(" " + _vm._s(_vm.getWord(item.revenue_rating)) + " ")]) : _vm._e(), _c('div', {
          staticClass: "segment-value"
        }, [_vm._v(_vm._s(_vm.localeData(item.revenue)))])])];
      }
    }, {
      key: "body_profit",
      fn: function (_ref5) {
        var _item$product7;

        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [(_item$product7 = item.product) !== null && _item$product7 !== void 0 && _item$product7.id ? _c('div', {
          class: ['segment__' + _vm.getWord(item.profit_rating), 'mr6', 'segment-word']
        }, [_vm._v(" " + _vm._s(_vm.getWord(item.profit_rating)) + " ")]) : _vm._e(), _c('div', {
          staticClass: "d-flex float-right align-items-center segment-value"
        }, [_vm._v(_vm._s(_vm.localeData(item.profit)))])])];
      }
    }, {
      key: "body_avgPurchasePrice",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex float-right align-items-center"
        }, [_vm._v(_vm._s(_vm.formatPrice(item.avgPurchasePrice)))])];
      }
    }, {
      key: "body_losses",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "d-flex float-right align-items-center"
        }, [_vm._v(_vm._s(_vm.formatPrice(item.losses)))])];
      }
    }, {
      key: "body_per_losses",
      fn: function (_ref8) {
        var _item$product8;

        var item = _ref8.item;
        return [_c('div', {
          staticClass: "d-flex float-right align-items-center"
        }, [_vm._v(" " + _vm._s((_item$product8 = item.product) !== null && _item$product8 !== void 0 && _item$product8.id ? (item.per_losses * 100).toFixed(0) : '-') + " ")])];
      }
    }, {
      key: "body_per_margin",
      fn: function (_ref9) {
        var _item$product9;

        var item = _ref9.item;
        return [_c('div', {
          staticClass: "d-flex float-right align-items-center"
        }, [_vm._v(" " + _vm._s((_item$product9 = item.product) !== null && _item$product9 !== void 0 && _item$product9.id ? (item.per_margin * 100).toFixed(0) : '-') + " ")])];
      }
    }, {
      key: "body_avgRetailPrice",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "d-flex float-right align-items-center"
        }, [_vm._v(_vm._s(_vm.formatPrice(item.avgRetailPrice)))])];
      }
    }, {
      key: "body_count_sku",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "d-flex float-right align-items-center"
        }, [_vm._v(_vm._s(item.count_sku))])];
      }
    }, {
      key: "body_segments",
      fn: function (_ref12) {
        var _item$product10;

        var item = _ref12.item;
        return [(_item$product10 = item.product) !== null && _item$product10 !== void 0 && _item$product10.id ? _c('div', {
          staticClass: "d-flex align-items-center justify-content-center"
        }, [_c('div', {
          class: ['segment__' + _vm.getWord(item.quantity_rating)]
        }, [_vm._v(" " + _vm._s(_vm.getWord(item.quantity_rating)) + " ")]), _c('div', {
          class: ['segment__' + _vm.getWord(item.revenue_rating)]
        }, [_vm._v(" " + _vm._s(_vm.getWord(item.revenue_rating)) + " ")]), _c('div', {
          class: ['segment__' + _vm.getWord(item.profit_rating)]
        }, [_vm._v(" " + _vm._s(_vm.getWord(item.profit_rating)) + " ")])]) : _vm._e()];
      }
    }])
  }), _c('custom-navbar', {
    staticStyle: {
      "position": "absolute",
      "bottom": "25px",
      "width": "100%"
    },
    attrs: {
      "item_name": "Позиций",
      "items": _vm.selected
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('b-dropdown', {
          attrs: {
            "no-caret": "",
            "variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticStyle: {
                  "font-size": "16px"
                }
              }, [_vm._v("Действия")])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          on: {
            "click": _vm.createDocOutcoming
          }
        }, [_vm._v(" Списать ")])], 1), _vm.selected.length === 1 ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.showMovement
          }
        }, [_vm._v(" Товародвижение ")]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.total
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }