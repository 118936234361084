<template>
  <div
    class="table"
    :class="{ 'new-height': items.length }"
  >
    <product-movement-modal :product="items.find((obj) => obj.product?.id === selected[0])?.product" />
    <div class="table-content">
      <div class="p-3 d-flex">
        <analytics-product-search
          :products="getProducts"
          @input="setProducts"
        />
        <div
          class="d-flex"
          style="flex: 1"
        >
          <group-by class="ml-3 mr-3" />
          <e-button
            class="ml-auto mr-3"
            size="m"
            style="height: 32px"
            @click="downloadExel"
          >
            <img
              src="/img/icons/download.svg"
              alt="download"
            />
          </e-button>
          <b-dropdown
            no-caret
            variant="none"
          >
            <template #button-content>
              <div class="btn-more">
                <img
                  src="/img/icons/settings.svg"
                  alt=""
                />
              </div>
            </template>
            <b-dropdown-item v-b-modal.sort-table-modal> Изменить столбцы </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <resizable-table
        table_name="abc-tbl_segments"
        :default_fields="fields"
        :height="total > 20 ? '550px' : '600px'"
        :items="items"
        :busy="loading"
        @sort_change="sort"
      >
        <template #head_id>
          <div class="head_id">
            <e-checkbox
              :checked="allSelected"
              @click="allSelected = !allSelected"
            />
            <p>№</p>
          </div>
        </template>
        <template #body_name="{ item }">
          <div>
            <analytics-item-row
              :loading_category="loading_category"
              :row="item"
              type="abc"
              :items="group"
              :selected="active(item?.product?.id)"
              @open_category="open_category"
              @select_product="select(item?.product?.id)"
            />
          </div>
        </template>
        <template #body_category="{ item }">
          <div class="d-flex float-left align-items-center">
            {{ item.product?.id ? item.product?.category?.name || 'Товары без категории' : '-' }}
          </div>
        </template>
        <template #body_quantity="{ item }">
          <div class="d-flex justify-content-end align-items-center">
            <div
              v-if="item.product?.id"
              :class="['segment__' + getWord(item.quantity_rating), 'mr6', 'segment-word']"
            >
              {{ getWord(item.quantity_rating) }}
            </div>
            <div class="segment-value">{{ localeData(item.quantity) }}</div>
          </div>
        </template>
        <template #body_revenue="{ item }">
          <div class="d-flex justify-content-end align-items-center">
            <div
              v-if="item.product?.id"
              :class="['segment__' + getWord(item.revenue_rating), 'mr6', 'segment-word']"
            >
              {{ getWord(item.revenue_rating) }}
            </div>
            <div class="segment-value">{{ localeData(item.revenue) }}</div>
          </div>
        </template>
        <template #body_profit="{ item }">
          <div class="d-flex justify-content-end align-items-center">
            <div
              v-if="item.product?.id"
              :class="['segment__' + getWord(item.profit_rating), 'mr6', 'segment-word']"
            >
              {{ getWord(item.profit_rating) }}
            </div>
            <div class="d-flex float-right align-items-center segment-value">{{ localeData(item.profit) }}</div>
          </div>
        </template>
        <template #body_avgPurchasePrice="{ item }">
          <div class="d-flex float-right align-items-center">{{ formatPrice(item.avgPurchasePrice) }}</div>
        </template>
        <template #body_losses="{ item }">
          <div class="d-flex float-right align-items-center">{{ formatPrice(item.losses) }}</div>
        </template>
        <template #body_per_losses="{ item }">
          <div class="d-flex float-right align-items-center">
            {{ item.product?.id ? (item.per_losses * 100).toFixed(0) : '-' }}
          </div>
        </template>
        <template #body_per_margin="{ item }">
          <div class="d-flex float-right align-items-center">
            {{ item.product?.id ? (item.per_margin * 100).toFixed(0) : '-' }}
          </div>
        </template>

        <template #body_avgRetailPrice="{ item }">
          <div class="d-flex float-right align-items-center">{{ formatPrice(item.avgRetailPrice) }}</div>
        </template>
        <template #body_count_sku="{ item }">
          <div class="d-flex float-right align-items-center">{{ item.count_sku }}</div>
        </template>
        <template #body_segments="{ item }">
          <div
            v-if="item.product?.id"
            class="d-flex align-items-center justify-content-center"
          >
            <div :class="['segment__' + getWord(item.quantity_rating)]">
              {{ getWord(item.quantity_rating) }}
            </div>
            <div :class="['segment__' + getWord(item.revenue_rating)]">
              {{ getWord(item.revenue_rating) }}
            </div>
            <div :class="['segment__' + getWord(item.profit_rating)]">
              {{ getWord(item.profit_rating) }}
            </div>
          </div>
        </template>
      </resizable-table>
      <custom-navbar
        style="position: absolute; bottom: 25px; width: 100%"
        item_name="Позиций"
        :items="selected"
      >
        <template #buttons>
          <b-dropdown
            no-caret
            variant="dark"
          >
            <template #button-content> <div style="font-size: 16px">Действия</div> </template>
            <b-dropdown-item @click="createDocOutcoming"> Списать </b-dropdown-item>
            <!--            <b-dropdown-item @click.stop>Добавить в акцию</b-dropdown-item>-->
          </b-dropdown>
          <b-button
            v-if="selected.length === 1"
            variant="dark"
            @click="showMovement"
          >
            Товародвижение
          </b-button>
        </template>
      </custom-navbar>
    </div>

    <pagination
      :total="total"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import ResizableTable from '@/components/ResizableTable'
  import Pagination from '@/views/shared/components/table/Pagination'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal'
  import { create_document_by_type } from '@/utils/create_document_by_type'
  import { mapActions, mapGetters } from 'vuex'
  import AnalyticsProductSearch from '@/views/analytics/components/AnalyticsProductSearch.vue'
  import GroupBy from '@/views/analytics/components/GroupBy.vue'
  import AnalyticsItemRow from '@/views/analytics/components/MovementAnalytics/AnalyticsItemRow.vue'
  import { formatMoney } from '@/utils/formatMoney'
  export default {
    name: 'TableAllSegments',
    components: {
      AnalyticsItemRow,
      GroupBy,
      AnalyticsProductSearch,
      ProductMovementModal,
      CustomNavbar,
      Pagination,
      ResizableTable
    },
    props: {
      loading: {
        type: Boolean,
        default: true
      },
      loading_category: {
        type: Object
      },
      items: {
        type: Array,
        default: () => []
      },
      total: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        allSelected: false,
        selected: [],
        products: [],
        fields: [
          {
            label: 'Наименование товара',
            key: 'name',
            width: 300,
            sortable: true,
            checked: true
          },
          {
            label: 'Категория',
            key: 'category',
            width: 300,
            sortable: true,
            checked: true
          },
          {
            label: 'Количество, шт',
            key: 'quantity',
            width: 200,
            sortable: true,
            checked: true
          },
          {
            label: 'Выручка, ₽',
            key: 'revenue',
            width: 200,
            sortable: true,
            checked: true
          },
          {
            label: 'Прибыль, ₽',
            key: 'profit',
            width: 200,
            sortable: true,
            checked: true
          },
          {
            label: 'Прибыль, %',
            key: 'per_margin',
            width: 200,
            sortable: true,
            checked: true
          },
          {
            label: 'Потери, ₽',
            key: 'losses',
            width: 200,
            sortable: true,
            checked: true
          },
          {
            label: 'Потери, %',
            key: 'per_losses',
            width: 200,
            sortable: true,
            checked: true
          },
          {
            label: 'Сегменты',
            key: 'segments',
            sortable: true,
            width: 200,
            checked: true,
            class: 'text-center'
          },
          {
            label: 'Ср. закуп. цена, ₽',
            key: 'avgPurchasePrice',
            sortable: true,
            width: 200,
            checked: true,
            class: 'text-center'
          },
          {
            label: 'Ср. розн. цена, ₽',
            key: 'avgRetailPrice',
            sortable: true,
            width: 200,
            checked: true,
            class: 'text-center'
          },
          {
            label: 'Кол-во SKU, шт',
            key: 'count_sku',
            sortable: true,
            width: 200,
            checked: true,
            class: 'text-center'
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        abcChecked: 'analytics/getAbcChecked',
        getProducts: 'analytics/getAbcProduct',
        getGroup: 'analytics/getAbcGroupBy',
        dateRange: 'operprocess/getDateRange',
        getSuppliers: 'analytics/getAbcSuppliers',
        getBranches: 'analytics/getAbcBranches',
        getCategories: 'analytics/getAbcOpenedCategory',
        getGroupBy: 'analytics/getAbcGroupBy'
      }),
      group() {
        return [...this.getGroup['abc'].filter((el) => el.grouped).map((el) => el.id), 'product']
      }
    },
    watch: {
      allSelected() {
        if (this.allSelected) {
          this.selected = this.items.map((obj) => obj.product?.id)
        } else {
          this.selected = []
        }
      }
    },
    methods: {
      ...mapActions({
        setProducts: 'analytics/setAbcProduct'
      }),
      open_category(value) {
        this.$emit('categoryChange', value)
      },
      async createDocOutcoming() {
        const selectedProducts = []
        this.selected.forEach((id) => {
          const find = this.items.find((obj) => obj.product?.id === id)
          if (find) {
            selectedProducts.push(find.product)
          }
        })
        const doc = create_document_by_type(
          'write-off',
          this.currentBranch.id,
          selectedProducts.map((el) => {
            return {
              product: el?.id,
              price: el.purchasePrice,
              retailPrice: el.retailPrice,
              delta: 0
            }
          }),
          null
        )
        const { data } = await this.$apollo.mutate({
          mutation: doc.mutation,
          variables: doc.variables
        })
        const route = doc.getRoute(data)
        if (route) this.$router.push(route)
      },
      showMovement() {
        this.$bvModal.show('product-movement')
      },
      sort(val) {
        this.$emit('sort', val)
      },
      getWord(num) {
        if (num === 3) {
          return 'A'
        } else if (num === 2) {
          return 'B'
        } else if (num === 1) {
          return 'C'
        }
        return '-'
      },
      handlerPagination(val) {
        this.$emit('handler-pagination', val)
      },
      localeData(num) {
        const number = (+num?.toFixed(2))?.toLocaleString()
        const arr = number.split(',')
        if (!arr[1]) {
          return number + ',00'
        }
        if (arr[1]?.length === 1) {
          return number + '0'
        }
        return number
      },
      active(item) {
        return this.selected.includes(item)
      },
      select(val) {
        if (!this.selected.includes(val)) {
          this.selected.push(val)
        } else {
          this.selected = this.selected.filter((obj) => obj !== val)
        }
      },
      formatPrice(val) {
        return formatMoney(val, 2)
      },
      async downloadExel() {
        const { data } = await this.$apollo.query({
          query: require('../../gql/AbcExel.graphql'),
          variables: {
            input: {
              period: {
                begin: this.dateRange.date[0] ?? new Date(1970, 1, 1),
                end: new Date()
              },
              segment: this.getNormalTabIndex,
              branches: this.getBranches.map((obj) => obj.id),
              suppliers: this.getSuppliers.map((obj) => obj.id),
              categories: this.getCategories,
              pagination: {
                skip: 0,
                take: 20
              },
              group_by: this.getGroup['abc'].filter((el) => el.grouped).map((obj) => obj.id),
              products: this.getProducts.map((obj) => obj.id),
              a_metric: 80,
              b_metric: 15,
              c_metric: 5
            }
          }
        })
        if (data?.ProductsABCExcel) {
          const link = document.createElement('a')
          link.href = data?.ProductsABCExcel?.url
          link.download = 'exel'
          link.click()
          URL.revokeObjectURL(link.href)
        } else {
          this.$noty.error('Ошибка при загруке файла')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-content {
    display: flex;
    flex-direction: column;
    background: white;
  }
  .segment-word {
    margin-left: auto;
  }
  .segment-value {
    width: 70px;
    display: flex;
    justify-content: end;
  }
  ::v-deep th.cursor {
    padding: 16px !important;
  }

  ::v-deep() {
    #resizeable-table {
      display: flex !important;
      flex: 1 !important;
    }

    .segment {
      margin-left: 6px;
      &__A {
        margin-left: 6px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #00cb91;
      }
      &__B {
        margin-left: 6px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #ffa800;
      }
      &__C {
        margin-left: 6px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #e53835;
      }
      &__- {
        margin-left: 6px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .table {
      .all_segment {
        flex: 1;
      }
    }
  }

  .table {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100% - 60px);
    &-content {
      position: relative;
      display: flex;
      flex: 1;
    }
  }

  ::v-deep .all_segment {
    min-height: calc(100% - 53px) !important;
  }
  ::v-deep .cursor {
    .td-div {
      display: flex !important;
      float: left !important;
      justify-content: flex-start !important;
      width: 100% !important;
    }
  }
  .new-height {
    ::v-deep .card {
      height: initial !important;
    }
  }
  .head_id {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .product_column {
    p {
      display: flex;
      margin: 0;
    }
    .art {
      display: flex;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
      &__articul {
        margin-right: 7px;
      }
    }
  }
  .mr6 {
    margin-left: 0 !important;
    margin-right: 6px !important;
  }
</style>
