<template>
  <div
    class="segment-container"
    :class="{ margin_lr: name === 'B' }"
  >
    <div class="segment_name">
      <div class="title__segment">Сегмент</div>
      <div :class="['segment__' + name]">{{ name }}</div>
    </div>

    <div class="stats">
      <div class="stat">
        <div class="name__stat">Количество, шт</div>
        <div class="stat__numbers">
          {{ localeData(segment?.quantity ?? 0) }}
        </div>
      </div>
      <div class="stat">
        <div class="name__stat">Ср. цена ед., ₽</div>
        <div class="stat__numbers">
          {{ segment?.quantity ? localeData((segment?.revenue ?? 0) / (segment.quantity ?? 0)) : '-' }}
        </div>
      </div>
    </div>
    <div class="stats">
      <div class="stat">
        <div class="name__stat">Выручка, ₽</div>
        <div class="stat__numbers">
          {{ localeData(segment?.revenue ?? 0) }}
        </div>
      </div>
      <div class="stat">
        <div class="name__stat">Прибыль, ₽</div>
        <div class="stat__numbers">
          {{ localeData(segment?.profit ?? 0) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Segment',
    props: {
      segment: {
        type: Object,
        default: () => {}
      },
      name: {
        type: String,
        default: ''
      }
    },
    methods: {
      localeData(num) {
        return (+num?.toFixed(2) ?? 0)?.toLocaleString()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .margin_lr {
    margin: 0 20px;
  }
  .segment-container {
    margin-bottom: 20px;
    flex: 1;
    background: #ffffff;
    border-radius: 6px;
    padding: 22px 22px 6px 22px;
    .segment_name {
      display: flex;
      margin-bottom: 22px;
      .title__segment {
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #313131;
      }
      ::v-deep() {
        .segment {
          margin-left: 6px;
          &__A {
            margin-left: 6px;
            font-family: 'Gilroy-Medium', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #00cb91;
          }
          &__B {
            margin-left: 6px;
            font-family: 'Gilroy-Medium', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #ffa800;
          }
          &__C {
            margin-left: 6px;
            font-family: 'Gilroy-Medium', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #e53835;
          }
        }
      }
    }

    .stats {
      display: flex;
      .stat {
        &:first-child {
          margin-right: 16px;
          margin-bottom: 16px;
        }
        &:nth-child(2) {
          margin-right: 16px;
          margin-bottom: 16px;
        }
        .name__stat {
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #888888;
        }
        .stat__numbers {
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #313131;
        }
        padding: 16px;
        flex: 1;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        border-radius: 6px;
      }
    }
  }
  @media (max-width: 1350px) {
    .margin_lr {
      margin: 0 0 20px 0 !important;
    }
  }
</style>
