var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tables-container"
  }, [_c('b-tabs', {
    staticClass: "mb16",
    attrs: {
      "active-nav-item-class": "font-weight-bold"
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Все сегменты"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Сегмент А"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Сегмент B"
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Сегмент C"
    }
  })], 1), _c('table-all-segments', {
    attrs: {
      "loading": _vm.loading,
      "total": _vm.total,
      "items": _vm.items,
      "loading_category": _vm.loading_category
    },
    on: {
      "sort": _vm.sort,
      "groupBy": _vm.groupBy,
      "categoryChange": _vm.categoryChange,
      "handler-pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }