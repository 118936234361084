<template>
  <div class="segment">
    <segment
      :segment="status.segment_a"
      name="A"
    />
    <segment
      :segment="status.segment_b"
      name="B"
    />
    <segment
      :segment="status.segment_c"
      name="C"
    />
  </div>
</template>

<script>
  import Segment from '@/views/analytics/components/Abc/Segment'

  export default {
    name: 'SegmentsStats',
    components: { Segment },
    props: {
      status: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .segment {
    display: flex;
  }
  @media (max-width: 1350px) {
    .segment {
      display: block !important;
    }
  }
</style>
