var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "abc-filters-container"
  }, [_c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.getCategories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('work-period', {
    staticClass: "date",
    attrs: {
      "type_doc": "abc"
    }
  }), _c('e-select', {
    staticClass: "branch-filter",
    attrs: {
      "value": _vm.getBranches,
      "multiple": "",
      "select_name": "branch",
      "max_len_chose": 10,
      "max_selected_count": 1,
      "options": _vm.getBranchList,
      "placeholder": "Магазин"
    },
    on: {
      "input": _vm.inputBranches
    }
  }), _c('div', {
    staticClass: "category-filter",
    on: {
      "!click": function ($event) {
        return _vm.showLoadModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "category"
  }, [_c('span', {
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.categoriesCount))]), _c('img', {
    staticStyle: {
      "transform": "rotate(180deg)"
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": "arrow-up"
    }
  })])]), _c('e-select', {
    staticClass: "supplier-filter",
    attrs: {
      "value": _vm.getSuppliers,
      "multiple": "",
      "select_name": "supplier",
      "max_len_chose": 10,
      "max_selected_count": 1,
      "options": _vm.suppliers_list,
      "placeholder": "Все поставщики"
    },
    on: {
      "input": _vm.inputSuppliers
    }
  }), _c('div', {
    staticClass: "ml-3",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" Сбросить ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }