<template>
  <div class="abc-anal">
    <abc-filters @update-filters="updateFilters" />
    <segments-stats :status="status" />
    <abc-tabs
      :items="items"
      :total="total"
      :loading="loading"
      :loading_category="loading_category"
      @groupBy="groupBy"
      @sort="sort"
      @categoryChange="categoryChange"
      @update-segment="updateSegment"
      @handler-pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import AbcTabs from '@/views/analytics/components/Abc/AbcTabs'
  import AbcFilters from '@/views/analytics/components/Abc/AbcFilters'
  import SegmentsStats from '@/views/analytics/components/Abc/SegmentsStats'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'AbcAnalytics',
    components: { SegmentsStats, AbcFilters, AbcTabs },
    apollo: {
      ProductsABC: {
        query: require('../gql/ProductsABC.graphql'),
        fetchPolicy: 'no-cache',
        debounce: 200,
        deep: true,
        variables() {
          return {
            input: {
              period: {
                begin: this.dateRange.date[0] ?? new Date(1970, 1, 1),
                end: this.dateRange.date[1] ?? new Date()
              },
              segment: this.getNormalTabIndex,
              branches: this.getBranches.map((obj) => obj.id),
              suppliers: this.getSuppliers.map((obj) => obj.id),
              categories: this.getCategories,
              pagination: this.pagination,
              group_by: this.getGroupBy['abc'].filter((el) => el.grouped).map((obj) => obj.id),
              products: this.getProducts.map((obj) => obj.id),
              checked: this.abcChecked,
              a_metric: 80,
              b_metric: 15,
              c_metric: 5
            }
          }
        },
        result({ data }) {
          if (data?.ProductsABC) {
            this.total = data?.ProductsABC?.total ?? 0
            this.items = data?.ProductsABC?.list ?? []
            this.status = data?.ProductsABC?.status
            this.loading_category = {}
          }
          this.loading = false
        },
        error() {
          this.loading = false
        }
      }
    },
    data() {
      return {
        loading: true,
        segment: 0,
        status: {},
        items: [],
        group_by: [],
        loading_category: {},
        filters: {
          suppliers: [],
          branches: [],
          selected_categories: []
        },
        total: 0,
        pagination: {
          skip: 0,
          take: 20,
          order: []
        }
      }
    },
    computed: {
      ...mapGetters({
        dateRange: 'operprocess/getDateRange',
        abcChecked: 'analytics/getAbcChecked',
        getSuppliers: 'analytics/getAbcSuppliers',
        getBranches: 'analytics/getAbcBranches',
        getCategories: 'analytics/getAbcOpenedCategory',
        getProducts: 'analytics/getAbcProduct',
        getGroupBy: 'analytics/getAbcGroupBy'
      }),
      getNormalTabIndex() {
        if (this.segment === 1) return 3
        if (this.segment === 3) return 1
        if (this.segment === 2) return 2
        return 0
      }
    },
    methods: {
      ...mapActions({
        setGroupBy: 'analytics/setAbcGroupBy',
        setProducts: 'analytics/setAbcProduct'
      }),
      categoryChange(id) {
        this.loading_category[id] = true
      },
      sort(val) {
        this.pagination.skip = 0
        this.pagination.order = [
          {
            key: val.key,
            value: val.sort
          }
        ]
        this.$apollo.queries.ProductsABC.refetch()
      },
      updateFilters(val) {
        this.filters.suppliers = val.suppliers ?? []
        this.filters.branches = val.branches ?? []
        this.filters.selected_categories = val.selected_categories ?? []
        this.$apollo.queries.ProductsABC.refetch()
      },
      updateSegment(val) {
        this.segment = val
      },
      groupBy(group_by) {
        this.setGroupBy(group_by)
      },
      products(products) {
        this.setProducts(products)
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
        this.$apollo.queries.ProductsABC.refetch()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .abc-anal {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
