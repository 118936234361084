<template>
  <div class="tables-container">
    <b-tabs
      v-model="tabIndex"
      class="mb16"
      active-nav-item-class="font-weight-bold"
    >
      <b-tab title="Все сегменты" />
      <b-tab title="Сегмент А" />
      <b-tab title="Сегмент B" />
      <b-tab title="Сегмент C" />
    </b-tabs>
    <table-all-segments
      :loading="loading"
      :total="total"
      :items="items"
      :loading_category="loading_category"
      @sort="sort"
      @groupBy="groupBy"
      @categoryChange="categoryChange"
      @handler-pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import TableAllSegments from '@/views/analytics/components/Abc/TableAllSegments'

  export default {
    name: 'AbcTabs',
    components: { TableAllSegments },
    props: {
      loading: {
        type: Boolean,
        default: true
      },
      loading_category: {
        type: Object
      },
      total: {
        type: Number,
        default: 0
      },
      items: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        tabIndex: 0
      }
    },
    watch: {
      tabIndex() {
        this.$emit('update-segment', this.tabIndex)
      }
    },
    methods: {
      sort(val) {
        this.$emit('sort', val)
      },
      groupBy(val) {
        this.$emit('groupBy', val)
      },
      handlerPagination(val) {
        this.$emit('handler-pagination', val)
      },
      categoryChange(val) {
        this.$emit('categoryChange', val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tables-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .mb16 {
    margin-bottom: 16px;
  }
</style>
