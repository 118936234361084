var render = function render(){
  var _vm$segment$quantity, _vm$segment, _vm$segment2, _vm$segment$revenue, _vm$segment3, _vm$segment$quantity2, _vm$segment$revenue2, _vm$segment4, _vm$segment$profit, _vm$segment5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "segment-container",
    class: {
      margin_lr: _vm.name === 'B'
    }
  }, [_c('div', {
    staticClass: "segment_name"
  }, [_c('div', {
    staticClass: "title__segment"
  }, [_vm._v("Сегмент")]), _c('div', {
    class: ['segment__' + _vm.name]
  }, [_vm._v(_vm._s(_vm.name))])]), _c('div', {
    staticClass: "stats"
  }, [_c('div', {
    staticClass: "stat"
  }, [_c('div', {
    staticClass: "name__stat"
  }, [_vm._v("Количество, шт")]), _c('div', {
    staticClass: "stat__numbers"
  }, [_vm._v(" " + _vm._s(_vm.localeData((_vm$segment$quantity = (_vm$segment = _vm.segment) === null || _vm$segment === void 0 ? void 0 : _vm$segment.quantity) !== null && _vm$segment$quantity !== void 0 ? _vm$segment$quantity : 0)) + " ")])]), _c('div', {
    staticClass: "stat"
  }, [_c('div', {
    staticClass: "name__stat"
  }, [_vm._v("Ср. цена ед., ₽")]), _c('div', {
    staticClass: "stat__numbers"
  }, [_vm._v(" " + _vm._s((_vm$segment2 = _vm.segment) !== null && _vm$segment2 !== void 0 && _vm$segment2.quantity ? _vm.localeData(((_vm$segment$revenue = (_vm$segment3 = _vm.segment) === null || _vm$segment3 === void 0 ? void 0 : _vm$segment3.revenue) !== null && _vm$segment$revenue !== void 0 ? _vm$segment$revenue : 0) / ((_vm$segment$quantity2 = _vm.segment.quantity) !== null && _vm$segment$quantity2 !== void 0 ? _vm$segment$quantity2 : 0)) : '-') + " ")])])]), _c('div', {
    staticClass: "stats"
  }, [_c('div', {
    staticClass: "stat"
  }, [_c('div', {
    staticClass: "name__stat"
  }, [_vm._v("Выручка, ₽")]), _c('div', {
    staticClass: "stat__numbers"
  }, [_vm._v(" " + _vm._s(_vm.localeData((_vm$segment$revenue2 = (_vm$segment4 = _vm.segment) === null || _vm$segment4 === void 0 ? void 0 : _vm$segment4.revenue) !== null && _vm$segment$revenue2 !== void 0 ? _vm$segment$revenue2 : 0)) + " ")])]), _c('div', {
    staticClass: "stat"
  }, [_c('div', {
    staticClass: "name__stat"
  }, [_vm._v("Прибыль, ₽")]), _c('div', {
    staticClass: "stat__numbers"
  }, [_vm._v(" " + _vm._s(_vm.localeData((_vm$segment$profit = (_vm$segment5 = _vm.segment) === null || _vm$segment5 === void 0 ? void 0 : _vm$segment5.profit) !== null && _vm$segment$profit !== void 0 ? _vm$segment$profit : 0)) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }